<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseCaeSection-${section.id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ section.name }} <small class="badge bg-light-secondary ms-1" /></a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseCaeSection-${section.id}`"
        visible
      >
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr><!-- comentar con Begoña que campos exactamente ve la host y cuales ella -->
                <th>Title</th>
                <th>Section</th>
                <th>Subsection</th>
                <th>Year</th>
                <th>Renew</th>
                <!-- <th>Documents</th> -->
                <th>Need feedback</th>
                <th>Deadline</th>
                <th>Status</th>
                <!-- <th>Published</th> -->
                <th>Updated</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="hazard in section.hazards"
              >
                <tr
                  v-for="file in hazard.files"
                  :key="file.id"
                >
                  <td>
                    <strong>{{ file.title }}</strong>
                  </td>
                  <td>
                    <span>{{ section.name }}</span>
                  </td>
                  <td>
                    <span>{{ hazard.subsections ? hazard.subsections.map(u => u.name).join(', ') : '-' }}</span>
                  </td>
                  <td>{{ file.year }}</td>
                  <td>{{ file.renew_year }}</td>
                  <!-- <td>
                    1
                  </td> -->
                  <td>
                    <span
                      v-if="!file.need_feedback"
                      class="badge bg-light-danger"
                    >No</span>
                    <span
                      v-if="file.need_feedback"
                      class="badge bg-light-success"
                    >Yes</span>
                  </td>
                  <td>{{ file.deadline }}</td>
                  <td v-html="file.status_table" />
                  <!-- <td v-if="file.responses && file.responses[0]">
                    <span
                      v-if="file.responses[0].accepted === null"
                      class="badge bg-light-secondary"
                    >Pending</span>
                    <span
                      v-if="file.responses[0].accepted === true"
                      class="badge bg-light-success"
                    >Accepted</span>
                    <span
                      v-if="file.responses[0].accepted === false"
                      class="badge bg-light-danger"
                    >Denied</span>
                  </td>
                  <td v-else>
                    -
                  </td> -->
                  <!-- <td>
                    <span
                      v-if="!file.published"
                      class="badge bg-light-danger"
                    >No</span>
                    <span
                      v-if="file.published"
                      class="badge bg-light-success"
                    >Yes</span>
                  </td> -->
                  <td><small>{{ file.formatted_date }}</small></td>
                  <td class="text-end">
                    <a
                      :href="file.url"
                      target="_blank"
                    >
                      <i data-feather="eye" />
                    </a>
                    <a
                      :href="$router.resolve({name: 'cae.view-document', params: {id: file.id}, query: {host: user.hosts[0].id, actAs: $route.query.actAs, prefilterUser: $route.query.prefilterUser}}).href"
                      target="_blank"
                      class="ms-1"
                    >
                      <i data-feather="external-link" />
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    section: { type: Object, required: true },
  },
  data() {
    return {
      sections: [],
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      file: 'cae/file',
      filters: 'filters/filters',
      fileIcreas: 'cae/fileIcreas',
      user: 'cae/icrea',
    }),
  },
  mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
