<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <div class="content-header-left col-md-9 col-12 mb-0">
                <div class="row breadcrumbs-top profile__avatar">
                  <div class="col-12">
                    <div class="avatar avatar-xl avatar-custom float-start">
                      <img
                        :src="user.avatarUrl"
                        alt="name icrea"
                        class="round"
                      >
                    </div>
                    <h2 class="content-header-title  mb-0">
                      {{ user.name }}
                      <span>{{ user.hosts ? user.hosts.map(e => e.name).join(', ') : '' }}</span>
                      <!-- <small><span class="badge bg-light-primary dis-ib">Institution name</span></small> -->
                    </h2>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              @click="window.history.length > 1 ? $router.go(-1) : $router.push({name:'cae.index'})"
            ><i data-feather="chevron-left" /></a>
            <span
              class="dis-ib ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Search documents"
              @click="$store.dispatch('modals/toggleConvoFilters', true)"
            >
              <a
                class="btn btn-icon btn-primary"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasFilters"
                aria-controls="offcanvasFilters"
              ><i data-feather="search" /></a>
            </span>
          </div>
        </div>
      </div>
      <hr>
      <div class="content-body content-body--senior-call">

        <p class="mb-2">
          Last updated: <strong>{{ user.updated_at }}</strong>
        </p>

        <div class="row">
          <div class="col-12">
            <!-- inicio sólo admin -->
            <!-- los enlaces llevan al master de cada workplace o hazard -->
            <div
              v-if="loggedUser.roles.includes('super-admin')"
              class="row"
            >
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body ">
                    <h4 class="mb-2">
                      Workplace
                    </h4>
                    <ul class="list-group list-group-flush">
                      <li
                        v-for="workplace in user.workplaces"
                        :key="workplace.id"
                        class="list-group-item"
                      >
                        <a
                          href="#"
                          class="badge bg-light-primary"
                        >{{ workplace.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body ">
                    <h4 class="mb-2">
                      Hazards
                    </h4>
                    <ul class="list-group list-group-flush">
                      <li
                        v-for="hazard in user.hazards"
                        :key="hazard.id"
                        class="list-group-item"
                      >
                        <a
                          href="#"
                          class="badge bg-light-primary"
                        >{{ hazard.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- fin sólo admin -->

            <div class="row">
              <div class="col">
                <hr>
                <h3>Documents</h3>

                <div class="card shadow-none bg-transparent mb-0 ms-0">
                  <div class="card-body ps-0">
                    <a
                      href=""
                      class="text-dark"
                    ><i data-feather="minimize-2" /> collapse all</a>
                    <a
                      href=""
                      class="ms-2 text-dark"
                    ><i data-feather="maximize-2" /> uncollapse all</a>
                  </div>
                </div>

                <DocumentSection
                  v-for="section in user.sections"
                  :key="section.id"
                  :section="section"
                />
              </div>
            </div>
          </div>
        </div>
        <Search />
      </div>

    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import Search from './partials/Search.vue'
import DocumentSection from './partials/DocumentSection.vue'

export default {
  components: {
    Search,
    DocumentSection,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      section: 'cae/item',
      filters: 'filters/filters',
      loggedUser: 'auth/admin',
      institution: 'cae/institution',
      user: 'cae/icrea',
    }),
  },
  async mounted() {
    await this.$store.dispatch('cae/fetchUser', this.$route.params.id)
  },
  methods: {
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
  },
}
</script>
